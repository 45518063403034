module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rashaad R. Randall","short_name":"Rashaad R. Randall","start_url":"/","background_color":"#1A2A3C","theme_color":"#1A2A3C","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d8dedee6fb54fcd45f84bd98603bfea0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"258039719","head":true,"exclude":[],"defer":true,"cookieDomain":"rashaadrandall.com","anonymize":false,"respectDNT":false,"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"4197192346961553"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
